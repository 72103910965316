import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide84/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide84/image/img.png"
import slider1 from "@components/pageGuide/guides/guide84/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide84/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide84/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Contact Center",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      6 claves para automatizar la capacitación de agentes de Contact Center
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      La automatización ayuda a mejorar la formación de los agentes y optimizar el proceso de aprendizaje. Implementa una plataforma de e-learning para que accedan a materiales en cualquier momento. Utiliza simulaciones para reforzar habilidades en situaciones reales.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Automatización de formación con flujos personalizados.",
      },
      {
        key: 2,
        text: "Seguimiento automatizado del progreso de agentes."
      },
      {
        key: 3,
        text: "Uso de bots para dudas frecuentes durante la capacitación.",
      },
      {
        key: 4,
        text: "Análisis de métricas para medir la efectividad de la capacitación.",
      },
      {
        key: 5,
        text: "Medir el progreso y ajustar el contenido.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Configura evaluaciones automáticas para medir el progreso y ajustar el contenido según las necesidades. Recuerda que, con esta guía, podrás conocer cómo integrar chatbots y asistentes virtuales en la capacitación.
          <br /><br />
          Por último, descubre más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Mejora la formación de tu equipo con procesos más eficientes.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
